import { ChatMessage } from '../../types';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import env from '~env';
import logger from '../logger';
import {
  chatIsConnectedAtom,
  chatMessageListAtom,
  chatSocketAtom,
} from '../state';
import { connectToSocket } from '../utils';
import useEventChatRoom from './use-event-chat-room.hook';

const socketUrl = env.publicUrls.wssIVSChat;

interface Props {
  eventId: string;
  userName: string;
  enabled?: boolean;
  onMessage?: (message: ChatMessage) => void;
  onEvent?: (event: any) => void;
}

/**
 * Create a connection to the chat room of the event.
 *
 * This hook is only intended for read operations, it receives
 * messages and events from the room and exposes them in Jotai atoms.
 *
 * For best performance it should be started once in a parent component.
 * To obtain the list of messages and the list of events use the Jotai atoms.
 *
 * NOTE: There is another hook called useChatSocketActions that contains reusable
 * write operations to avoid polluting the current *READ ONLY* hook!
 */
export const useConnectChatSocket = (props: Props) => {
  const { onMessage, eventId, enabled = true, userName, onEvent } = props;
  const { token, getQuery: getTokenQuery } = useEventChatRoom({
    eventId,
    userName,
    enabled,
  });
  const [socket, setChatSocket] = useAtom(chatSocketAtom);

  const [chatIsConnected, setChatIsConnected] = useAtom(chatIsConnectedAtom);

  const [chatMessageList, setChatMessageList] = useAtom(chatMessageListAtom);

  const connectSocket = () => {
    // The websocket is already open, it is not necessary to open it again.
    if (socket?.readyState === WebSocket.OPEN) return;
    logger.debug('connecting to the chat room');

    const connection = connectToSocket({
      token,
      url: socketUrl,
      onOpen: handleSocketOpen,
      onClose: handleSocketClose,
      onError: handleSocketError,
      onMessage: handleSocketMessage,
    });

    setChatSocket(connection);
  };

  const closeSocket = () => {
    if (!socket) return;
    socket.close();
  };

  const reconnectSocket = () => {
    getTokenQuery.refetch();
    logger.debug('reconnecting to the chat room');
  };

  const handleSocketOpen = useCallback(() => {
    setChatIsConnected(true);
    logger.debug('connected to the chat room');
  }, [setChatIsConnected]);

  const handleSocketError = useCallback((event: Event) => {
    logger.error('chat room socket error:', event);
  }, []);

  const handleSocketMessage = useCallback(
    (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      const eventType = data.Type;
      switch (eventType) {
        case 'EVENT':
          onEvent?.(data);
          break;
        case 'ERROR':
          logger.error('chat room error:', data);
          break;
        case 'MESSAGE':
          onMessage?.(data);
          break;
        default:
          logger.error('unknown chat room event:', event);
      }
    },
    [onEvent, onMessage],
  );

  const handleSocketClose = useCallback((_event: CloseEvent) => {
    logger.debug('disconnected from the chat room');
    setChatIsConnected(false);
    reconnectSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) return;
    logger.debug('chat room token:', token);

    closeSocket();
    connectSocket();

    return () => {
      closeSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    socket,
    chatIsConnected,
    chatMessageList,
    setChatMessageList,
  };
};

export default useConnectChatSocket;
