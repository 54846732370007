import { useNizza } from '../../nizza-store';

export const useAccount = () => {
  const account = useNizza(x => x.account);
  const accountName = useNizza(x => x.accountName);
  const accountStatus = useNizza(x => x.accountStatus);
  const refetchAccount = useNizza(x => x.refetchAccount);
  const isLoading = ['idle', 'loading'].includes(accountStatus);
  const isError = accountStatus === 'error';
  const isSuccess = accountStatus === 'success';

  return {
    account,
    accountName,
    isLoading,
    isError,
    isSuccess,
    accountStatus,
    refetchAccount,
  };
};
