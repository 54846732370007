import {
  GlobalStore,
  Logger,
  NizzaProductDataSourceConfig,
  NizzaProductRequestOriginTypes,
  NizzaStore,
  RenderProps,
  Runtime,
  RuntimeManagerConfig,
  RuntimeType,
  getNizza,
} from '@nizza/core';
import { render as litRender } from 'lit';
import {
  ComponentType,
  ReactElement,
  ReactNode,
  StrictMode,
  cloneElement,
  isValidElement,
} from 'react';
import { Root, createRoot } from 'react-dom/client';
import loggerBase from '~logger';
import { createProductDataSource } from './product-data-source';
import { waitForElement } from './waitForElement';

type BaseConfig = Omit<
  RuntimeManagerConfig<NizzaStore>,
  'globalId' | 'getStore'
>;
type MinimalRuntimeConfig = Partial<BaseConfig> &
  Required<Pick<RuntimeManagerConfig<NizzaStore>, 'env' | 'logger'>>;

const logger = Logger.withPrefix(loggerBase, 'RuntimeUtils');

export function initializeNizzaRuntime(
  config: MinimalRuntimeConfig,
): Runtime<NizzaStore> {
  return new Runtime({
    ...config,
    globalId: 'nizza',
    getStore: getNizza,
    bundler: {
      isProd: import.meta.env.PROD,
      stage: import.meta.env.STAGE,
      ...config.bundler,
    },
  });
}

export function registerReactComponent<T extends GlobalStore>(
  runtime: Runtime<T>,
  rootId: string,
  components: (ComponentType<any> | ReactElement)[],
) {
  logger.debug('Registering React component', { rootId, components });
  runtime.registerComponent(rootId, async (rootId, props) => {
    const root = await renderReactComponent(rootId, components, props);
    if (!root) return;

    return {
      element: root,
      updateProps: (newProps: RenderProps) => {
        reactRender(root, components, newProps);
        logger.debug('Updated props for React component', { rootId, newProps });
      },
      unmount: () => {
        root.unmount();
        logger.debug('Unmounted React component', { rootId });
      },
    };
  });
}

export function registerLitComponent<T extends GlobalStore>(
  runtime: Runtime<T>,
  defaultRootId: string,
  wcName: string,
) {
  logger.debug('Registering Lit component', { defaultRootId, wcName });
  runtime.registerComponent(defaultRootId, async (rootId, props) => {
    const element = await renderLitComponent(rootId, wcName, props);
    if (!element) return;

    return {
      element,
      updateProps: (newProps: RenderProps) => {
        updateLitProps(element, newProps);
        logger.debug('Updated props for Lit component', { rootId, newProps });
      },
      unmount: () => {
        const container = document.getElementById(rootId);
        if (container) {
          litRender(null, container);
        } else {
          logger.debug(`Container with ID "${rootId}" not found.`);
        }
        logger.debug('Unmounted Lit component', { rootId });
      },
    };
  });
}

export function reactRender(
  root: Root,
  components: (ComponentType<any> | ReactElement)[],
  props: RenderProps,
): void {
  const componentTree = createComponentTree(components, props);
  logger.debug('Rendering React component tree', { componentTree, props });

  root.render(<StrictMode>{componentTree}</StrictMode>);
}

function createComponentTree(
  components: (ComponentType<any> | ReactElement)[],
  props: RenderProps,
): ReactNode {
  return components.reduceRight<ReactNode>(
    (children, ComponentOrElement, index) => {
      const isLast = index === components.length - 1;
      const elementProps = isLast ? { ...props, children } : { children };

      if (isValidElement(ComponentOrElement)) {
        return cloneElement(ComponentOrElement, elementProps as any);
      }

      const Component = ComponentOrElement as ComponentType<any>;
      return <Component {...elementProps} />;
    },
    null,
  );
}

async function renderReactComponent(
  rootId: string,
  components: (ComponentType<any> | ReactElement)[],
  props: RenderProps,
): Promise<Root | null> {
  const container = await getContainer(rootId);
  if (!container) return null;

  const root = createRoot(container);
  reactRender(root, components, props);
  logger.debug('React component rendered', { rootId, components, props });
  return root;
}

async function renderLitComponent(
  rootId: string,
  wcName: string,
  props: RenderProps,
): Promise<HTMLElement | null> {
  const container = await getContainer(rootId);
  if (!container) return null;

  const element = document.createElement(wcName);
  updateLitProps(element, props);
  litRender(element, container);
  logger.debug('Lit component rendered', { rootId, wcName, props });
  return element;
}

function updateLitProps(element: HTMLElement, props: RenderProps) {
  Object.entries(props).forEach(([key, value]) => {
    if (key in element) {
      (element as any)[key] = value;
    } else {
      logger.debug(
        `Property "${key}" does not exist on element "${element.tagName.toLowerCase()}"`,
      );
    }
  });
}

async function getContainer(rootId: string): Promise<HTMLElement | null> {
  const { promise } = waitForElement([`#${rootId}`]);
  try {
    const result = await promise;
    if (!result.element) throw new Error('The root element does not exist');
    return result.element;
  } catch (error: any) {
    handleError(error, rootId);
    return null;
  }
}

function handleError(error: Error, rootId: string): void {
  if (error.message.includes('waitForElement')) {
    logger.debug('waitForElement error', { rootId, error });
  } else {
    logger.error('Error finding container', { rootId, error });
  }
}

export const setDefaultProductDataSource = <T extends GlobalStore>(
  runtime: Runtime<T>,
  defaultLimit = 10,
) => {
  const runtimeType = runtime.meta.type;
  let origin: NizzaProductRequestOriginTypes;

  switch (runtimeType) {
    case RuntimeType.VtexIO:
    case RuntimeType.VtexLegacy:
    case RuntimeType.VtexFastStore:
      origin = NizzaProductRequestOriginTypes.VTEX;
      break;

    case RuntimeType.Platform:
      origin = NizzaProductRequestOriginTypes.PLATFORM;
      break;

    default:
      origin = NizzaProductRequestOriginTypes.UNKNOWN;
      break;
  }

  const config: NizzaProductDataSourceConfig = {
    origin,
    defaultLimit,
  };

  runtime.store.setProductDataSource(createProductDataSource(config));
  logger.debug('Default product data source set', { config });
};
