import { useState } from 'react';
import { MetaDataClient, RealTimeStreamInfo } from '../core';
import { AddToCart } from '../../types';

const DEFAULT_REAL_TIME_STREAM_INFO = {
  startTime: 'UNKNOWN',
  viewerCount: 0,
  status: 'UNKNOWN',
};

export const useMetaDataClient = (): MetaDataClient => {
  const [productsInCart, setProductsInCart] = useState<AddToCart[]>([]);
  const [realTimeStreamInfo, setRealTimeStreamInfo] =
    useState<RealTimeStreamInfo>(DEFAULT_REAL_TIME_STREAM_INFO);

  const onTextMetadataCue = (cue: any) => {
    const { action, data, startTime, viewerCount, status } = JSON.parse(
      cue?.text,
    );

    switch (action) {
      case 'sendivsdatarealtime': {
        setRealTimeStreamInfo({ startTime, viewerCount, status });
        break;
      }
      case 'sendaddtocart':
        {
          const { id, sessionId, productId, productImageUrl, productName } =
            data;

          setProductsInCart(prev => [
            ...prev,
            {
              id,
              sessionId,
              productId,
              productImageUrl,
              productName,
            },
          ]);
        }
        break;
      default:
        break;
    }
  };

  const resetRealTimeStreamInfo = () => {
    setRealTimeStreamInfo(DEFAULT_REAL_TIME_STREAM_INFO);
  };

  return {
    realTimeStreamInfo,
    productsInCart,
    onTextMetadataCue,
    resetRealTimeStreamInfo,
    setProductsInCart,
  };
};
