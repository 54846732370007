import { ChatMessage } from '../types/nizza/chat';
import { atom } from 'jotai';

// Socket connection to IVS chat
export const chatSocketAtom = atom<WebSocket | undefined>(undefined);
chatSocketAtom.debugLabel = 'chatSocketAtom';

// Flag to indicate if IVS chat is connected
export const chatIsConnectedAtom = atom(false);
chatIsConnectedAtom.debugLabel = 'chatIsConnectedAtom';

// List of messages received at the IVS chat room
export const chatMessageListAtom = atom<ChatMessage[]>([]);
chatMessageListAtom.debugLabel = 'chatMessageListAtom';
