import {
  Logger,
  NizzaGetCollectionHandler,
  NizzaGetProductHandler,
  NizzaGetProductHandlerInput,
  NizzaGetProductListHandler,
  NizzaGetProductListHandlerInput,
  NizzaProduct,
  NizzaProductDataSource,
  NizzaProductDataSourceConfig,
  VtexProduct,
  customFetch,
  getNizza,
} from '@nizza/core';
import baseLogger from '~logger';
import { buildVtexCatalogUrl, vtexToNizzaProduct } from '../../vtex';

const logger = Logger.withPrefix(baseLogger, 'VtexProductDataSource');

type Config = NizzaProductDataSourceConfig & {
  corsProxy?: boolean;
};

export class VtexProductDataSource implements NizzaProductDataSource {
  private accountName: string;
  private accountHost: string | null;

  constructor(readonly config: Config) {
    const nizza = getNizza();

    if (!nizza.account) throw new Error('Nizza account is invalid');

    const { account, host } = nizza.account;
    this.accountName = account;
    this.accountHost = host;
  }

  getProduct: NizzaGetProductHandler = async input => {
    const data = await this.fetchProducts(input);
    return this.convertProductData(data)[0] || null;
  };

  getProductList: NizzaGetProductListHandler = async input => {
    const data = await this.fetchProducts(input);
    return this.convertProductData(data);
  };

  getCollection: NizzaGetCollectionHandler = async input => {
    const data = await this.fetchProducts(input);
    const products = this.convertProductData(data);

    let collectionName = '';
    const collectionId = input?.collectionId ?? '';

    if (collectionId && data.length) {
      collectionName = data[0].productClusters[collectionId];
    }

    return {
      id: collectionId,
      name: collectionName,
      products,
    };
  };

  private convertProductData(data: VtexProduct[]): NizzaProduct[] {
    const { accountApplyTax } = this.config;
    return data.map(product =>
      vtexToNizzaProduct(product, this.accountHost, accountApplyTax),
    );
  }

  private async fetchProducts(
    input: NizzaGetProductHandlerInput | NizzaGetProductListHandlerInput = {},
  ): Promise<VtexProduct[]> {
    try {
      const response = await this.tryFetchProducts(input, false);
      return this.handleResponse(response);
    } catch (error: any) {
      logger.warn(
        'Error fetching products using host. Retrying with proxy.',
        error,
      );
      return this.retryFetchProductsWithProxy(input, error);
    }
  }

  private async retryFetchProductsWithProxy(
    input: NizzaGetProductHandlerInput | NizzaGetProductListHandlerInput,
    hostError: Error,
  ): Promise<VtexProduct[]> {
    try {
      const response = await this.tryFetchProducts(input, true);
      return this.handleResponse(response);
    } catch (proxyError: any) {
      const errorMessage = `Failed to fetch products. Host error: ${hostError.message}, Proxy error: ${proxyError.message}`;
      logger.error(errorMessage, proxyError);
      throw new Error(errorMessage);
    }
  }

  private async tryFetchProducts(
    input: NizzaGetProductHandlerInput | NizzaGetProductListHandlerInput,
    useProxy: boolean,
  ): Promise<Response> {
    const url = buildVtexCatalogUrl({
      limit: this.config.defaultLimit,
      corsProxy: useProxy,
      corsProxyOrigin: useProxy ? 'ls-products-cors-error' : undefined,
      account: this.accountName,
      accountHost: this.accountHost,
      ...input,
    });

    return customFetch({
      url,
      contentTypeJson: false,
      raw: true,
      /**
       * FIXME: If this time increases a lot, the variation selector is delayed
       * but if the user's network is slow or the VTEX API takes to answer cause
       * that this request fails.
       */
      timeout: 5000, // 5s
    });
  }

  private async handleResponse(response: Response): Promise<VtexProduct[]> {
    if (!response.ok) {
      const errorText = await response.text();
      const errorMessage = `Failed to fetch products. Status: ${response.status}, Response: ${errorText}`;
      logger.error(errorMessage);
      throw new Error(errorMessage);
    }

    try {
      return await response.json();
    } catch (jsonError: any) {
      const errorMessage = `Error parsing response JSON: ${jsonError.message}`;
      logger.error(errorMessage, jsonError);
      throw new Error(errorMessage);
    }
  }
}
