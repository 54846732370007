import { validRuntime } from '../utils';
import { ExternalRuntime } from './external-runtime';
import { LocalRuntime } from './local-runtime';
import { VtexIORuntime } from './vtex-io-runtime';
import { VtexLegacyRuntime } from './vtex-legacy-runtime';
import { VtexFastStoreRuntime } from './vtex-faststore-runtime';
import { PlatformRuntime } from './platform';

export * from './external-runtime';
export * from './local-runtime';
export * from './vtex-io-runtime';
export * from './vtex-legacy-runtime';

export const runtimeList = [
  validRuntime(LocalRuntime),
  validRuntime(VtexLegacyRuntime),
  validRuntime(VtexIORuntime),
  validRuntime(ExternalRuntime),
  validRuntime(VtexFastStoreRuntime),
  validRuntime(PlatformRuntime),
];
