import { action, computed, makeObservable, observable, when } from 'mobx';
import { NizzaAccount, getAccount } from '../accounts';
import { BroadcastChannelEventBus } from '../event-buses';
import {
  AccountStatus,
  AddToCartConfig,
  INizzaStore,
  NizzaConfig,
  NizzaProductDataSource,
} from '../types';
import { shortId } from '../utils/helpers';
import { Logger } from '../utils/logger';

export class NizzaStore implements INizzaStore {
  static readonly version = '6';
  private static _instance: NizzaStore;

  readonly instanceId = shortId();
  readonly log = new Logger('nz');
  readonly coreLogger = this.log.createLogger('core');

  private _config: NizzaConfig | null = null;
  private _account?: NizzaAccount;
  private _accountStatus: AccountStatus = 'idle';
  private _productDataSource: NizzaProductDataSource | undefined;
  private _eventBus = new BroadcastChannelEventBus('nizza', this.coreLogger);

  private constructor() {
    this.coreLogger = Logger.withPrefix(this.coreLogger, 'NizzaStore');

    makeObservable<
      NizzaStore,
      '_config' | '_account' | '_productDataSource' | '_accountStatus'
    >(
      this,
      {
        _config: observable,
        _account: observable,
        _productDataSource: observable,
        _accountStatus: observable,
        config: computed,
        account: computed,
        accountName: computed,
        accountStatus: computed,
        configure: action,
        refetchAccount: action,
        setProductDataSource: action,
        setAddToCartConfig: action,
        setProductId: action,
      },
      { name: 'NizzaStore', autoBind: true },
    );

    this.refetchAccount = this.refetchAccount.bind(this);
  }

  static instance(): NizzaStore {
    const windowInstanceExists = !!window?.nizza?.store;

    if (!windowInstanceExists) {
      const newInstance = new NizzaStore();
      NizzaStore._instance = newInstance;
    } else {
      NizzaStore._instance = window.nizza.store;
    }

    if (!window.nizza) {
      (window as any).nizza = {};
      (window as any).nz = {};
      NizzaStore._instance.coreLogger.debug(`Initialized nizza window object`);
    }

    window.nizza.store = NizzaStore._instance;
    window.nz = window.nizza;
    return NizzaStore._instance;
  }

  async configure(config: NizzaConfig) {
    try {
      await this.updateConfig(config);
    } catch (err) {
      this.setAccountStatus('error');
      this.coreLogger.error('Error running configure', err);
    }
  }

  private async updateConfig(config: NizzaConfig) {
    const { account: currentAccount } = this._config ?? {};
    this._config = config;

    if (currentAccount !== config.account) {
      await this.fetchAccount(config.account);
    }

    if (config.getProductDataSource) {
      this.setProductDataSource(await config.getProductDataSource());
    }
  }

  private async fetchAccount(accountName: string) {
    this.setAccountStatus('loading');
    try {
      const account = await getAccount(accountName);
      this.setAccount(account);
      this.setAccountStatus('success');
    } catch (error) {
      this.setAccountStatus('error');
      this.coreLogger.error('Error fetching account:', error);
    }
  }

  private setAccount(account: NizzaAccount) {
    this._account = account;
  }

  private setAccountStatus(status: AccountStatus) {
    this._accountStatus = status;
  }

  setProductDataSource(dataSource: NizzaProductDataSource) {
    this._productDataSource = dataSource;
  }

  setProductId(id: string) {
    if (!this._config) return;
    this._config.productId = id;
  }

  setOrderFormId(id: string) {
    if (!this._config) return;
    this._config.orderFormId = id;
  }

  setAddToCartConfig(config: AddToCartConfig) {
    if (!this._config) {
      throw new Error(
        'you cannot set addToCartConfig before running configure()',
      );
    }
    this._config.addToCartConfig = config;
  }

  async refetchAccount() {
    if (!this._config?.account) return;
    await this.fetchAccount(this._config.account);
  }

  get version() {
    return NizzaStore.version;
  }

  get config() {
    return this._config;
  }

  get account() {
    return this._account;
  }

  get accountStatus() {
    return this._accountStatus;
  }

  get accountName() {
    return this._config?.account;
  }

  get productDataSource() {
    return this._productDataSource;
  }

  get eventBus() {
    return this._eventBus;
  }
}

export const getNizza = (onInit?: (nizza: NizzaStore) => void) => {
  const nizza = NizzaStore.instance();

  when(
    () => !!nizza.account,
    () => onInit?.(nizza),
  );

  return nizza;
};
